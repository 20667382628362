import { motion } from 'framer-motion';

interface BackdropProps {
  children: React.ReactNode;
  onClick: () => void;
}

export function Backdrop({ children, onClick }: BackdropProps) {
  return (
    <motion.div
      className="fixed left-0 top-0 z-30 h-screen w-full bg-black/40"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}
