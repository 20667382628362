import { Variants } from 'framer-motion';

export const hamburguerMenuVariant: Variants = {
  enter: {
    x: -400,
  },
  visible: {
    x: 0,
    transition: { ease: 'circIn', duration: 0.4 },
  },
  exit: {
    x: -400,
    transition: { ease: 'circOut', duration: 0.3 },
  },
};
