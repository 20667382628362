import React from 'react';

import MenuItem from './MenuItem';

interface MenuProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export default function Menu({ children, footer }: MenuProps) {
  return (
    <div className="flex w-full flex-col">
      <div className="flex items-start justify-start gap-6 py-8">
        {children}
      </div>
      {footer}
    </div>
  );
}

Menu.Item = MenuItem;
