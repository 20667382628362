import React from 'react';
import Navbar from './navbar/Navbar';
import { useRouter } from 'next/router';
import { AnimatePresence } from 'framer-motion';
import {
  Bars3CenterLeftIcon,
  EnvelopeIcon,
  ExclamationCircleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { useCountry, useUser } from '../../hooks';
import SelectCountryModal from '../modals/SelectCountryModal';
import PrimaryButton from '../common/PrimaryButton';
import TemplateIcon from '../icons/TemplateIcon';
import DocumentIcon from '../icons/DocumentIcon';
import AppointmentIcon from '../icons/AppointmentIcon';
import PaymentIcon from '../icons/PaymentIcon';
import NavbarMenu from './navbar/NavbarMenu';
import Menu from './navbar/sub-menu/Menu';
import TextButton from '../common/TextButton';
import WhatsAppIcon from '../icons/WhatsAppIcon';
import AuthModal from '../auth/AuthModal';

export interface Section {
  name: string;
  href: string;
  userOnly: boolean;
  icon: React.ReactNode;
}

const sections: Section[] = [
  {
    name: 'Plantillas',
    href: '/',
    userOnly: false,
    icon: <TemplateIcon className="h-4 w-4 text-gray-500" />,
  },
  {
    name: 'Mis documentos',
    href: '/orders',
    userOnly: true,
    icon: <DocumentIcon className="h-4 w-4 text-gray-500" />,
  },
  // {
  //   name: 'Citas',
  //   href: '/appointments',
  //   userOnly: true,
  //   icon: <AppointmentIcon className="h-4 w-4 text-gray-500" />,
  // },
  {
    name: 'Mis pagos',
    href: '/payments',
    userOnly: true,
    icon: <PaymentIcon className="h-4 w-4 text-gray-500" />,
  },
];

export default function MainLayout({ children }) {
  const { user } = useUser();
  const { countryPhoneData } = useCountry();
  const router = useRouter();
  const [isToggled, setIsToggled] = React.useState(false);
  const [helpMenuOpen, setHelpMenuOpen] = React.useState(false);
  const [selectCountryModalOpen, setSelectCountryModalOpen] =
    React.useState(false);
  const [authModalOpen, setAuthModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!user) return;
    else setAuthModalOpen(false);
  }, [user]);

  return (
    <div className="relative flex min-h-screen w-full flex-col">
      <Navbar>
        <div className="flex w-full flex-col">
          <div className="flex h-full w-full items-center justify-between">
            {/* > display-lg */}
            <div className="hidden items-center gap-1 lg:flex">
              <Navbar.Logo />
              {user &&
                sections.map((section) => (
                  <Navbar.Item
                    key={section.href}
                    href={section.href}
                    active={router.pathname === section.href}
                  >
                    {section.name}
                  </Navbar.Item>
                ))}
            </div>
            <div className="hidden lg:flex">
              <div className="flex items-center gap-3">
                <Navbar.CountrySelector
                  onClick={() => setSelectCountryModalOpen(true)}
                />
                <Navbar.Button
                  onClick={() => setHelpMenuOpen(!helpMenuOpen)}
                  className={`${helpMenuOpen && 'bg-gray-100'}`}
                >
                  <QuestionMarkCircleIcon className="h-6 w-6" />
                </Navbar.Button>
                {user ? (
                  <Navbar.Avatar />
                ) : (
                  <PrimaryButton
                    onClick={() => setAuthModalOpen(true)}
                    className="px-4"
                  >
                    Ingresar
                  </PrimaryButton>
                )}
              </div>
            </div>

            {/* display-xs,sm,md */}
            <div className="lg:hidden">
              <Navbar.Logo />
            </div>
            <Navbar.Button
              onClick={() => setIsToggled(true)}
              className="lg:hidden"
            >
              <Bars3CenterLeftIcon className="h-6 w-6" />
            </Navbar.Button>
          </div>

          {helpMenuOpen && (
            <Menu
            // footer={
            //   <div className="flex w-full items-center justify-center gap-2 py-2">
            //     <p className="text-sm font-medium text-gray-700">
            //       ¿Necesitas una cita?
            //     </p>
            //     <TextButton text="Agendar cita" onClick={() => {}} />
            //   </div>
            // }
            >
              <Menu.Item
                text="Contacto"
                icon={<PhoneIcon className="h-6 w-6 text-gray-700" />}
                content={countryPhoneData?.phone}
                onClick={() => router.push(`tel:${countryPhoneData.phone}`)}
              />
              <Menu.Item
                text="Correo"
                icon={<EnvelopeIcon className="h-6 w-6 text-gray-700" />}
                content="info.ve@widulegal.com"
                onClick={() => router.push('mailto:info.ve@widulegal.com')}
              />
              <Menu.Item
                text="Chat en vivo"
                icon={<WhatsAppIcon className="h-6 w-6 text-gray-700" />}
                content={countryPhoneData?.wa}
                onClick={() =>
                  router.push(`https://wa.me/${countryPhoneData?.wa}`)
                }
              />
              {/* <Menu.Item
                text="Preguntas frecuentes"
                icon={
                  <ExclamationCircleIcon className="h-6 w-6 text-gray-700" />
                }
                content="Preguntas frecuentes"
                onClick={() => {}}
              /> */}
            </Menu>
          )}
        </div>
      </Navbar>
      <AnimatePresence mode="wait">
        {isToggled && (
          <NavbarMenu
            sections={sections}
            setIsToggled={setIsToggled}
            isToggled={isToggled}
            setSelectCountryModalOpen={setSelectCountryModalOpen}
          />
        )}
      </AnimatePresence>
      <main className="pt-16 h-full grow bg-gray-50 lg:pt-[72px]">
        {children}
      </main>
      <SelectCountryModal
        isOpen={selectCountryModalOpen}
        setIsOpen={setSelectCountryModalOpen}
      />
      <AuthModal
        modalOpen={authModalOpen}
        closeModal={() => setAuthModalOpen(false)}
      />
    </div>
  );
}
