import React from 'react';
import { hamburguerMenuVariant } from '../../../animations/hamburguer_menu';
import { Backdrop } from '../../common/Backdrop';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import {
  ArrowLeftOnRectangleIcon,
  QuestionMarkCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useUser } from '../../../hooks';
import { Section } from '../MainLayout';
import { LogoutIcon } from '@avila-tek/ui';
import { useRouter } from 'next/router';

interface NavbarMenuProps {
  sections: Section[];
  setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
  isToggled: boolean;
  setSelectCountryModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function NavbarMenu({
  sections,
  isToggled,
  setIsToggled,
  setSelectCountryModalOpen,
}: NavbarMenuProps) {
  const { user, widuSignOut } = useUser();
  const router = useRouter();

  return (
    <Backdrop onClick={() => setIsToggled(false)}>
      <motion.div
        variants={hamburguerMenuVariant}
        initial="enter"
        animate="visible"
        exit="exit"
        className="fixed left-0 top-0 z-50 flex h-full w-full max-w-sm flex-col items-start justify-start gap-6 bg-white px-4 pt-3"
      >
        <header className="flex w-full items-center justify-between">
          <Navbar.Logo />
          <Navbar.Button onClick={() => setIsToggled(!isToggled)}>
            <XMarkIcon className="h-6 w-6" />
          </Navbar.Button>
        </header>
        <nav className="flex w-full grow flex-col gap-6">
          {user && (
            <ul className="flex flex-col items-start gap-1">
              {sections.map((section) => (
                <Navbar.Item
                  key={section.href}
                  href={section.href}
                  active={router.pathname === section.href}
                  className="w-full justify-start"
                >
                  {section.icon}
                  {section.name}
                </Navbar.Item>
              ))}
            </ul>
          )}
          <div className="flex grow flex-col gap-6">
            <ul className="flex grow flex-col items-start justify-end gap-1">
              <div className="px-2">
                <Navbar.CountrySelector
                  onClick={() => setSelectCountryModalOpen(true)}
                />
              </div>
              <Navbar.Item href="#" className="w-full justify-start">
                <QuestionMarkCircleIcon className="h-4 w-4 text-gray-500" />
                Ayuda
              </Navbar.Item>
            </ul>
            {user ? (
              <div className="flex w-full items-center justify-between border-t border-gray-200 px-4 py-6">
                <div className="flex gap-3">
                  <figure className="flex h-10 w-10 gap-3 rounded-full">
                    <img
                      src={user?.profilePicture ?? '/avatarPlaceholder.png'}
                      alt="Profile Picture"
                      className="h-full w-full rounded-full"
                    />
                  </figure>
                  <div className="flex cursor-default flex-col items-start">
                    <span className="text-sm font-semibold text-gray-700">
                      {user.firstName} {user.lastName}
                    </span>
                    <span className="text-xs text-gray-600">{user.email}</span>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={async (e) => await widuSignOut(e)}
                  className="group/logout my-1 flex items-center justify-start gap-2 rounded-lg px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  <LogoutIcon className="h-4 w-4 text-gray-500" />
                </button>
              </div>
            ) : (
              <div className="flex w-full items-center justify-between border-t border-gray-200 py-6">
                <Navbar.Item href="/login" className="w-full justify-start">
                  <ArrowLeftOnRectangleIcon className="h-4 w-4 text-gray-500" />
                  Ingresar
                </Navbar.Item>
              </div>
            )}
          </div>
        </nav>
      </motion.div>
    </Backdrop>
  );
}
