import React from 'react';
import IconButton from '../../../common/IconButton';

interface MenuItemProps {
  text: string;
  icon: React.ReactNode;
  content: string;
  onClick?: () => void;
}

export default function MenuItem({
  icon,
  text,
  content,
  onClick,
}: MenuItemProps) {
  return (
    <div className="flex items-center justify-center gap-4 p-3">
      <IconButton onClick={onClick}>{icon}</IconButton>
      <div className="flex flex-col items-start gap-1">
        <span className="text-sm font-semibold text-gray-900">{text}</span>
        <p className="text-sm text-gray-600">{content}</p>
      </div>
    </div>
  );
}
