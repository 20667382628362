import React from 'react';

interface IconButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

export default function IconButton({ onClick, children }: IconButtonProps) {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center rounded-lg border border-gray-200 bg-white p-3 shadow"
    >
      {children}
    </button>
  );
}
